import React from "react";
import { convertToLocalDate } from "../../../../Utils/Common/utils";
/**
 * data Columns for grid and mobile view
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Data Columns Array
 */
export const DataColumns = (onSyntaxClick, editScreen) => {
  const dataColumns = [
    {
      field: "Row no",
      title: "row_no",
      editable: false,
      show: false,
      width: "250px",
      width: "250px",
      minResizableWidth: 150,
      filterable: true,
    },
    {
      field: "les_cmd_id",
      title: "Command ID",
      editable: false,
      show: true,
      width: "600px",
      className: "edit-link",
      minResizableWidth: 50,
      cell: (props) => (
        <td
          className="edit-link"
          onClick={() => {
            editScreen(props.dataItem);
          }}
        >
          {props.dataItem.les_cmd_id}
        </td>
      ),
    },
    {
      field: "TenantName",
      title: "Tenant",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 150,
      filterable: true,
    },
    {
      field: "syntax",
      title: "Syntax",
      editable: false,
      show: true,
      width: "500px",
      minResizableWidth: 50,
      cell: (props) => (
        <td
          onClick={() => {
            onSyntaxClick(props.dataItem);
          }}
        >
          {props.dataItem.syntax}
        </td>
      ),
    },
    {
      field: "grp_nam",
      title: "Group Name",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
      filterable: false,
    },
    {
      field: "moddte",
      title: "Modified Date",
      editable: false,
      show: true,
      width: "150px",
      minResizableWidth: 50,
      filterable: false,
      cell: (props) => <td>{convertToLocalDate(props.dataItem.moddte)}</td>,
    },
    {
      field: "mod_usr_id",
      title: "Modified By",
      editable: false,
      show: true,
      width: "200px",
      minResizableWidth: 50,
    },
  ];

  return dataColumns;
};
export default DataColumns;
